import React, { useCallback, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { Container } from './styles';

import { formatDate, isScreenMobile } from '../../util/functions';

import ButtonSp from '../../components/ButtonSp';
import InputTextareaSp from '../../components/InputTextareaSp';
import InputCurrencySp from '../../components/InputCurrencySp';
import LabelSp from '../../components/LabelSp';

import InputTextSp from '../../components/InputTextSp';
import MonitorService from '../../services/MonitorService';

export default function MonitorApi() {
  const [url, setUrl] = useState('');
  const [tempo, setTempo] = useState(60);
  const [jwtToken, setJwtToken] = useState('');
  const [funcTimeOut, setFuncTimeOut] = useState();
  const [logErros, setLogErros] = useState('');
  const [iniciado, setIniciado] = useState(false);
  const [estatisticas, setEstatisticas] = useState({
    qtdReq: 0,
    qtdReqErro: 0,
  });

  // functions
  function getTitle() {
    const titleDefault = 'Monitoramento de APIs (Serviços)';
    const titleAdd = '';

    if (!isScreenMobile()) {
      return `${titleDefault} ${titleAdd}`;
    }

    return titleDefault;
  }

  const parar = useCallback(() => {
    setIniciado(false);
  }, []);

  const gerarLog = useCallback(
    (msg, substituir) => {
      if (substituir) {
        setLogErros(`${formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')} - ${msg}\n`);
      } else {
        setLogErros(`${logErros + formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')} - ${msg}\n`);
      }
    },
    [logErros]
  );

  const monitorar = useCallback(async () => {
    let msgLog = '';
    let pararProcesso = false;
    let qtdReqErro = 0;
    try {
      await MonitorService.monitorar(url, jwtToken);
    } catch (error) {
      qtdReqErro = 1;
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          // não autorizado / para o timer
          msgLog = 'Requisição não autorizada';
          pararProcesso = true;
        } else msgLog = `${error.toString()}`;
      } else {
        msgLog = `${error.toString()}`;
      }
    }

    setEstatisticas({
      qtdReq: estatisticas.qtdReq + 1,
      qtdReqErro: estatisticas.qtdReqErro + qtdReqErro,
    });
    if (msgLog) {
      gerarLog(msgLog);
    }
    if (pararProcesso) {
      parar();
    }
  }, [estatisticas.qtdReq, estatisticas.qtdReqErro, gerarLog, jwtToken, parar, url]);

  const criaTarefa = useCallback(async () => {
    const func = setTimeout(async () => {
      await monitorar();
      setFuncTimeOut(undefined);
    }, tempo * 1000);
    setFuncTimeOut(func);
  }, [monitorar, tempo]);

  // useEffects
  useEffect(() => {
    return () => {
      if (funcTimeOut) {
        clearTimeout(funcTimeOut);
      }
    };
  }, [funcTimeOut]);

  useEffect(() => {
    if (iniciado && funcTimeOut === undefined) {
      criaTarefa();
    } else if (!iniciado && funcTimeOut !== undefined) {
      if (funcTimeOut) {
        clearTimeout(funcTimeOut);
      }
      setFuncTimeOut(undefined);
    }
  }, [criaTarefa, funcTimeOut, iniciado]);

  // renders
  return (
    <Container className="container-page">
      <div className="p-grid">
        <div className="p-col-8">
          <span className="title">{getTitle()}</span>
        </div>
        <div className="p-col-12">
          <hr />
        </div>

        <div className="p-col-9 p-md-9 p-lg-9 p-fluid">
          <LabelSp>URI Api Serviço(GET)</LabelSp>
          <InputTextSp
            value={url}
            required
            disabled={!!iniciado}
            onChange={e => setUrl(e.target.value)}
            placeholder="http://localhost:3333/usuarios"
          />
        </div>

        <div className="p-col-3 p-md-3 p-lg-3 p-fluid">
          <LabelSp>Tempo(Segundos)</LabelSp>
          <InputCurrencySp
            digits={0}
            required
            disabled={!!iniciado}
            name="tempo"
            value={tempo}
            onChange={(e, v) => {
              setTempo(v);
            }}
          />
        </div>

        <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
          <LabelSp>JWt Token</LabelSp>
          <InputTextareaSp
            disabled={!!iniciado}
            placeholder="Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9..."
            rows={10}
            resize={false}
            value={jwtToken}
            onChange={e => {
              setJwtToken(e.target.value);
            }}
          />
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
          <ButtonSp
            className="p-button-danger"
            icon="pi pi-times"
            label="Parar"
            disabled={!iniciado}
            onClick={() => {
              gerarLog('Processo finalizado');
              parar();
            }}
          />

          <ButtonSp
            className="p-button"
            label="Iniciar"
            icon="pi pi-caret-right"
            disabled={!!iniciado}
            onClick={async () => {
              if (!tempo || tempo < 10) {
                toast.error('Tempo deve ser manior que 10');
                return;
              }

              if (!url) {
                toast.error('Informe a URI da Api');
                return;
              }
              gerarLog('Processo iniciado', true);
              setEstatisticas({ qtdReq: 0, qtdReqErro: 0 });

              setIniciado(true);
            }}
          />
        </div>

        <div className="p-col-8" style={{ marginTop: 10 }}>
          <LabelSp>Resultado</LabelSp>
        </div>

        <div className="p-col-12">
          <hr />
        </div>

        <div className="p-col-4 p-md-4 p-lg-4 p-fluid">
          <LabelSp>Total de Requisições</LabelSp>
          <InputTextSp value={estatisticas.qtdReq.toString()} disabled />
        </div>
        <div className="p-col-4 p-md-4 p-lg-4 p-fluid">
          <LabelSp>Req. com Erro</LabelSp>
          <InputTextSp value={estatisticas.qtdReqErro.toString()} disabled />
        </div>
        <div className="p-col-4 p-md-4 p-lg-4 p-fluid">
          <LabelSp>Req. com Sucesso</LabelSp>
          <InputTextSp
            value={(estatisticas.qtdReq - estatisticas.qtdReqErro).toString()}
            disabled
          />
        </div>

        <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
          <LabelSp>Log de Erros</LabelSp>
          <InputTextareaSp disabled rows={10} resize={false} value={logErros} />
        </div>
      </div>
    </Container>
  );
}
