/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';

import { Container } from './styles';
import InputCurrencySp from '../../components/InputCurrencySp';

import DesenvolvimentoService from '../../services/DesenvolvimentoService';
import AuthService from '../../services/AuthService';
import history from '../../services/history';
import {
  getPageParams,
  errorHandle,
  calcNaxItemsPage,
  isScreenMobile,
  padLeft,
  formatDate,
  isNumber,
  carregaListaVersoes,
  cloneObj,
} from '../../util/functions';

import { showMessage } from '../../components/MessageDialog';

import BotaoMenuGrid from '../../components/BotaoMenuGrid';

import DesenvolvimentoCrud from './crud';
import { StateScreen } from '../constants';
import CrudHeader from '../../components/CrudHeader';
import DataTableSp from '../../components/DataTableSp';
import LabelSp from '../../components/LabelSp';

import DropDownLazy from '../../components/DropdownLazy';

import { store } from '../../store';
import ClienteService from '../../services/ClienteService';
import { showLookup } from '../../store/modules/dialog/actions';
import ButtonSp from '../../components/ButtonSp';
import InputTextSp from '../../components/InputTextSp';
import ColaboradorService from '../../services/ColaboradorService';
import CalendarSp from '../../components/CalendarSp';
import { cfgPtBr } from '../../config/Constantes';
import DropdownSp from '../../components/DropdownSp';
import SistemaService from '../../services/SistemaService';
import ImgPdfView from '../../components/ImgPdfView';

export default function Desenvolvimento(props) {
  // useSelectors
  const filterVisible = useSelector(state => state.global.filterVisible);

  // useMemo
  const pageParams = useMemo(() => getPageParams(props.match.params, props.location.search), [
    props.location.search,
    props.match.params,
  ]);

  const filterService = useMemo(() => DesenvolvimentoService.getFilter(), []);

  // useStates
  const toBack = pageParams.toBack || '/desenvolvimentos';

  const [filter, setFilter] = useState(filterService);
  const [desenvolvimentos, setDesenvolvimentoes] = useState([]);

  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSelected, setPageSelected] = useState(0);
  const [colaboradorResponsavelSelecionada, setColaboradorResponsavelSelecionada] = useState(
    null
  );
  const [colaboradorProgramadorSelecionado, setColaboradorProgramadorSelecionado] = useState(
    null
  );

  const [sistemaSelecionado, setSistemaSelecionado] = useState(null);

  const [funcTimeOut, setFuncTimeOut] = useState();
  const [listaStatusSelecionado, setListaStatusSelecionado] = useState(
    JSON.parse(filter.listaStatusDesenvolvimento)
  );

  const [showPdf, setShowPdf] = useState(false);
  const [pdfSrc, setPdfSrc] = useState();
  const [idSistemaReport, setIdSistemaReport] = useState();

  const [showSelecionaHistorico, setShowSelecionaHistorico] = useState(false);
  const [sistemas, setSistemas] = useState([]);

  const listaStatusDesenvolvimento = [
    { value: '0', label: 'Pendente' },
    { value: '1', label: 'Pré-aprovado' },
    { value: '2', label: 'Aprovado' },
    { value: '3', label: 'Desenvolvendo' },
    { value: '4', label: 'Concluído' },
    { value: '5', label: 'Cancelado' },
    { value: '6', label: 'Liberado' },
  ];

  // useCallbacks
  const calcLimit = useCallback(() => {
    let ret;
    if (filterVisible) {
      ret = calcNaxItemsPage(6, 10, 16);
    } else {
      ret = calcNaxItemsPage(13, 17, 22);
    }
    setPageLimit(ret);
    return ret;
  }, [filterVisible]);

  const loadColaboradorResponsavel = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorService.findAll({
        nome: _nome,
        limit: 50,
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const printDesenvolvimento = async _filter => {
    showMessage('Confirmação', 'Deseja imprimir o relatório completo?', async idx => {
      const f = cloneObj(_filter);

      f.tipoRelatorio = idx;

      try {
        const retorno = await DesenvolvimentoService.getReport(f);
        const srcPdf = URL.createObjectURL(retorno);
        setPdfSrc(srcPdf);
        setShowPdf(true);
      } catch (err) {
        errorHandle(err);
      }
    });
  };

  const printHistoricoVersao = async idSistema => {
    try {
      const retorno = await DesenvolvimentoService.getHistoricoReport(idSistema);
      const srcPdf = URL.createObjectURL(retorno);
      setPdfSrc(srcPdf);
      setShowPdf(true);
    } catch (err) {
      errorHandle(err);
    }
  };

  const loadSistema = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await SistemaService.findAll({
        nome: _nome,
        limit: 50,
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadColaborador = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorService.findAll({
        nome: _nome,
        tipo: '0',
        limit: 50,
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const handleBuscar = useCallback(
    async (_filter, _page, resetPage = true) => {
      _filter.page = _page || 0;
      _filter.limit = calcLimit();

      try {
        const result = await DesenvolvimentoService.findAll({ ..._filter, resumo: 'resumo' });

        setDesenvolvimentoes(result.items);

        setTotalRecords(result.totalRecords);
        if (resetPage) {
          setFirst(0);
        }
      } catch (err) {
        errorHandle(err);
      }
    },
    [calcLimit]
  );

  const setFilterAndSearch = useCallback(
    async _filterValue => {
      if (JSON.stringify(_filterValue) !== JSON.stringify(filter)) {
        setFilter(_filterValue);

        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleBuscar(_filterValue);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [filter, funcTimeOut, handleBuscar]
  );

  function onPage(event) {
    const pagina = event.first / event.rows;
    setPageSelected(pagina);
    setFirst(event.first);
    handleBuscar(filter, pagina, false);
  }

  // useCallbacks
  const excluirRegistro = useCallback(
    async _id => {
      try {
        await DesenvolvimentoService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filter);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filter, handleBuscar]
  );

  // functions
  function getTitle() {
    const titleDefault = 'Desenvolvimento';
    let titleAdd = '';

    if (pageParams.stateScreen === StateScreen.stSearch) {
      titleAdd = '';
    }
    if (pageParams.stateScreen === StateScreen.stInsert) {
      titleAdd = '(Novo)';
    }
    if (pageParams.stateScreen === StateScreen.stUpdate) {
      titleAdd = `(Alterando Id: ${pageParams.idSelected})`;
    }
    if (pageParams.stateScreen === StateScreen.stView) {
      titleAdd = ` (Visualizando Id: ${pageParams.idSelected})`;
    }

    if (!isScreenMobile()) {
      return `${titleDefault} ${titleAdd}`;
    }

    return titleDefault;
  }

  function corStatus(status) {
    switch (status) {
      case 0:
        return '#ee3902';
      case 1:
        return '#93efaa';
      case 2:
        return '#00a629';
      case 3:
        return '#ebd300';
      case 4:
        return '#007ad9';
      case 5:
        return '#ff854a';
      case 6:
        return '#005469';
      default:
        return '#c8c8c8';
    }
  }

  const confirmaExclusao = useCallback(
    id => {
      showMessage('Confirmação', 'Confirma a exclusão do registro?', idx => {
        if (idx === 1) {
          excluirRegistro(id);
        }
      });
    },
    [excluirRegistro]
  );

  // const alternarTestado = useCallback(
  //   async desenvolvimento => {
  //     const testado = !desenvolvimento.testado;
  //     try {
  //       await DesenvolvimentoService.updateTeste(desenvolvimento.id, testado);
  //       desenvolvimento.testado = testado;
  //       toast.success('Registro atualizado com sucesso.');
  //       handleBuscar(filter, pageSelected, false);
  //     } catch (err) {
  //       errorHandle(err);
  //     }
  //   },
  //   [filter, pageSelected, handleBuscar]
  // );

  // useEffects
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      handleBuscar(filter, 0, true);
    }
    // desativado para evitar que a cada vez que o Desenvolvimento digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffects
  // Responsavel por recalcular a quantidade de registros da grid quando a div do filtro ocultar
  // ou ficar visivel

  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      calcLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcLimit]);

  useEffect(() => {
    const carregaSistemas = async () => {
      const ret = await loadSistema('');
      setSistemas(ret);
    };
    carregaSistemas();
  }, [loadSistema]);

  const lookupCliente = () => {
    const { dispatch } = store;
    const lookupConfig = ClienteService.getLookupConfig(objReturn => {
      const { id, nome } = objReturn;
      const cliente = {
        id,
        nome,
      };
      setFilterAndSearch({ ...filter, cliente, idCliente: cliente.id });
    });

    dispatch(showLookup(lookupConfig));
  };

  // renders
  return (
    <Container className="container-page page-enter-tab">
      <div className="p-grid">
        <CrudHeader
          title={getTitle()}
          disabledButtonInsert={!AuthService.checkRoles('DESENVOLVIMENTO_INSERIR')}
          showButtonFilter={pageParams.stateScreen === StateScreen.stSearch}
          showButtonInsert
          showButtonSearch
          optionsButtonMenu={{
            handles: [
              () => printDesenvolvimento(filter),
              () => setShowSelecionaHistorico(true),
            ],
            labels: ['Imprimir Seleção', 'Histórico de Versões'],
            icons: ['pi pi-print', 'pi pi-print'],
            disableds: [
              !AuthService.checkRoles('DESENVOLVIMENTO_IMPRIMIR'),
              !AuthService.checkRoles('DESENVOLVIMENTO_ACESSAR'),
            ],
          }}
          titleFilterDefault="Descrição Resumida"
          handleFilterDefault={text => {
            filter.descricaoResumida = text;
            setFilter({ ...filter, descricaoResumida: text });
            handleBuscar(filter);
          }}
          handleClearFilters={() => {
            setFilter({
              ...filter,
              descricaoResumida: '',
              idColaboradorResponsavel: null,
              cliente: null,
              idCliente: 0,
              idSistema: 0,
              id: 0,
              listaStatusDesenvolvimento: '["0", "1", "2","3"]',
              prioridade: '',
            });
            setListaStatusSelecionado(JSON.parse('["0", "1", "2","3"]'));
            setColaboradorResponsavelSelecionada(null);
            setSistemaSelecionado(null);
          }}
          handleButtonInsert={() => history.push('/desenvolvimentos/insert')}
          handleButtonSearch={() => handleBuscar(filter)}
        >
          <div className="p-col-4 p-md-2 p-lg-2 p-fluid">
            <LabelSp>Id</LabelSp>
            <InputCurrencySp
              autoFocus
              digits={0}
              value={filter?.id}
              onChange={(e, v) => {
                setFilterAndSearch({ ...filter, id: v });
              }}
            />
          </div>

          <div
            className="p-grid p-col-8 p-md-5 p-lg-5 p-fluid"
            style={{ margin: 0, padding: 0 }}
          >
            <div className="p-col-6 p-fluid">
              <LabelSp htmlFor="dataInicial">De: </LabelSp>
              <CalendarSp
                showIcon
                showOnFocus={false}
                appendTo={document.body}
                dateFormat="dd/mm/yy"
                yearNavigator
                locale={cfgPtBr}
                id="dataInicial"
                value={filter.dataInicio}
                yearRange="2010:2040"
                onChange={e => {
                  setFilterAndSearch({ ...filter, dataInicio: e.value });
                }}
              />
            </div>
            <div className="p-col-6 p-fluid">
              <LabelSp htmlFor="dataFinal">Até: </LabelSp>
              <CalendarSp
                appendTo={document.body}
                showIcon
                showOnFocus={false}
                locale={cfgPtBr}
                id="dataFinal"
                dateFormat="dd/mm/yy"
                value={filter.dataFim}
                yearNavigator
                yearRange="2010:2040"
                onChange={e => setFilterAndSearch({ ...filter, dataFim: e.value })}
              />
            </div>
          </div>

          <div className="p-col-12 p-md-5 p-lg-5 p-fluid">
            <LabelSp>Cliente</LabelSp>
            <div className="p-inputgroup">
              <ButtonSp
                type="button"
                icon="pi pi-search"
                className="p-button-secondary buttons"
                title="Buscar Cliente"
                onClick={() => lookupCliente()}
              />
              <ButtonSp
                type="button"
                icon="pi pi-trash"
                className="p-button-warning buttons"
                title="Limpar Cliente"
                style={{ marginLeft: 1 }}
                onClick={() => {
                  setFilterAndSearch({ ...filter, idCliente: 0, cliente: null });
                }}
              />

              <InputTextSp value={filter.cliente?.nome || 'Todos os Clientes'} disabled />
            </div>
          </div>

          <div className="p-col-12 p-md-4 p-lg-4 p-fluid">
            <LabelSp>Programador</LabelSp>
            <DropDownLazy
              autoLoad
              placeholder="Todos"
              showClear
              onChange={e => {
                setColaboradorProgramadorSelecionado(e);
                setFilterAndSearch({ ...filter, idColaboradorProgramador: e?.value });
              }}
              value={colaboradorProgramadorSelecionado}
              onFilter={async txtFilter => {
                const retorno = await loadColaborador(txtFilter);
                return retorno;
              }}
            />
          </div>

          <div className="p-col-12 p-md-4 p-lg-4 p-fluid">
            <LabelSp>Responsável</LabelSp>
            <DropDownLazy
              autoLoad
              placeholder="Todos"
              showClear
              onChange={e => {
                setColaboradorResponsavelSelecionada(e);
                setFilterAndSearch({ ...filter, idColaboradorResponsavel: e?.value });
              }}
              value={colaboradorResponsavelSelecionada}
              onFilter={async txtFilter => {
                const retorno = await loadColaboradorResponsavel(txtFilter);
                return retorno;
              }}
            />
          </div>

          <div className="p-col-12 p-md-4 p-lg-4 p-fluid">
            <LabelSp>Prioridade</LabelSp>
            <DropdownSp
              options={[
                { label: 'Todas', value: '' },
                { label: 'Baixa', value: '0' },
                { label: 'Moderada', value: '1' },
                { label: 'Normal', value: '2' },
                { label: 'Alta', value: '3' },
                { label: 'Máxima', value: '4' },
                { label: 'Bug', value: '5' },
              ]}
              placeholder="selecione"
              value={filter.prioridade}
              onChange={e => {
                setFilterAndSearch({ ...filter, prioridade: e?.value });
              }}
            />
          </div>

          <div className="p-col-6 p-md-4 p-lg-4 p-fluid">
            <LabelSp>Sistema</LabelSp>
            <DropDownLazy
              autoLoad
              placeholder="Todos"
              showClear
              onChange={e => {
                setSistemaSelecionado(e);
                setFilterAndSearch({ ...filter, idSistema: e?.value });
              }}
              value={sistemaSelecionado}
              onFilter={async txtFilter => {
                const retorno = await loadSistema(txtFilter);
                return retorno;
              }}
            />
          </div>

          <div className="p-col-6 p-md-4 p-lg-4 p-fluid">
            <LabelSp>Versão</LabelSp>
            <DropdownSp
              filter
              options={carregaListaVersoes()}
              placeholder="Todas"
              value={filter.versaoLiberacao}
              onChange={e => {
                setFilterAndSearch({ ...filter, versaoLiberacao: e.target?.value });
              }}
            />
          </div>

          <div className="p-col-6 p-md-4 p-lg-4 p-fluid">
            <LabelSp>Ordenação</LabelSp>
            <DropdownSp
              options={[
                { value: '0', label: 'Data/Status/Cliente' },
                { value: '1', label: 'Cliente/Status/Data' },
                { value: '2', label: 'Prioridade/Data/Status' },
              ]}
              placeholder="Selecione"
              value={filter.ordenacao}
              onChange={e => {
                setFilterAndSearch({ ...filter, ordenacao: e.target?.value });
              }}
            />
          </div>

          <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
            <LabelSp>Status</LabelSp>
            <MultiSelect
              value={listaStatusSelecionado}
              options={listaStatusDesenvolvimento}
              selectedItemTemplate={selectedDevTemplate}
              maxSelectedLabels={6}
              onChange={e => {
                setListaStatusSelecionado(e.value);
                setFilterAndSearch({
                  ...filter,
                  listaStatusDesenvolvimento: JSON.stringify(e.value),
                });
              }}
            />
          </div>
        </CrudHeader>
        {pageParams.stateScreen === StateScreen.stSearch ? renderSearch() : renderCrud()}
      </div>
    </Container>
  );

  function renderSearch() {
    return (
      <>
        {renderSelecionaHistorico()}
        {showPdf ? (
          <ImgPdfView
            title="Relatório"
            src={pdfSrc}
            visible={showPdf}
            setVisible={() => {
              setPdfSrc(undefined);
              setShowPdf(false);
            }}
          />
        ) : null}

        <div className="p-col-12 p-fluid datatable-doc-demo">
          <DataTableSp
            value={desenvolvimentos}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            lazy
            responsive
            totalRecords={totalRecords}
            first={first}
            onPage={onPage}
          >
            <Column
              field="id"
              header=" "
              style={{ width: 30 }}
              body={rowData => {
                const cor = corStatus(rowData.status);
                return (
                  <div
                    className="div-status"
                    style={{
                      background: cor,
                      color: cor,
                    }}
                  >
                    A
                  </div>
                );
              }}
            />
            <Column
              field="id"
              header="Id"
              className="grid-col-id-p"
              body={rowData => padLeft(rowData.id, 5)}
            />
            <Column
              field="dataLancamento"
              header="Data"
              className="grid-col-data-p"
              body={rowData => formatDate(`${rowData.dataLancamento}`, 'dd/MM/yy')}
            />

            <Column
              field="dataPrevisao"
              header="Previsão"
              className="grid-col-data-p p-p-4"
              body={rowData =>
                rowData.dataPrevisao ? formatDate(`${rowData.dataPrevisao}`, 'dd/MM/yy') : ''
              }
            />

            <Column
              field="dataConclusao"
              header="Concl."
              className="grid-col-data-p p-p-6"
              body={rowData =>
                rowData.dataConclusao ? formatDate(`${rowData.dataConclusao}`, 'dd/MM/yy') : ''
              }
            />
            <Column
              field="prioridade"
              className="grid-col grid-col-center"
              style={{ width: 30 }}
              header="Pri."
            />
            <Column
              field="nomeSistema"
              className="grid-col"
              style={{ width: 140 }}
              header="Sistema"
            />
            <Column
              field="nomeCliente"
              className="grid-col p-p-4 grid-col-nowrap"
              style={{ width: 250 }}
              header="Cliente"
            />

            <Column
              field="descricaoResumida"
              className="grid-col grid-col-nowrap"
              header="Descrição Resumida"
            />

            <Column
              field="versaoLiberacao"
              className="grid-col grid-col-center"
              style={{ width: 50 }}
              header="Ver."
            />

            {/* <Column
              field="testado"
              className="grid-col grid-col-center p-p-7"
              style={{ width: 50 }}
              header="Teste"
              body={rowData => {
                return (
                  <>
                    {rowData.status === 3 ? (
                      <>
                        <a
                          title={rowData.testado ? 'Sim' : 'Não'}
                          style={{
                            color: rowData.testado ? 'blue' : 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => alternarTestado(rowData)}
                        >
                          <span>{rowData.testado ? 'Sim' : 'Não'}</span>
                        </a>
                      </>
                    ) : null}
                  </>
                );
              }}
            /> */}

            <Column
              field="qtdTeste"
              body={rowData => {
                return (
                  <span style={{ color: rowData.statusUltimoTeste === 1 ? 'red' : '#333333' }}>
                    {rowData.qtdTeste}
                  </span>
                );
              }}
              className="grid-col grid-col-center p-p-7"
              style={{ width: 50 }}
              header="Q.Test"
            />

            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTableSp>
        </div>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => history.push(`/desenvolvimentos/${rowData.id}?view`),
          () => history.push(`/desenvolvimentos/${rowData.id}`),
          () => confirmaExclusao(rowData.id),
        ]}
        disableds={[
          false,
          !AuthService.checkRoles('DESENVOLVIMENTO_ALTERAR'),
          !AuthService.checkRoles('DESENVOLVIMENTO_EXCLUIR'),
        ]}
      />
    );
  }

  function renderCrud() {
    return (
      <DesenvolvimentoCrud
        idSelected={pageParams.idSelected}
        stateScreen={pageParams.stateScreen}
        toBack={toBack !== '/desenvolvimentos' ? toBack : ''}
        onClose={() => {
          history.push(toBack);
          if (toBack.includes('/desenvolvimentos')) {
            handleBuscar(filter, pageSelected, false);
          }
        }}
      />
    );
  }

  function renderSelecionaHistorico() {
    const footer = (
      <div style={{ textAlign: 'center' }}>
        <ButtonSp
          className="p-button-secondary"
          label="Voltar"
          icon="pi pi-chevron-circle-left"
          onClick={() => {
            if (setShowSelecionaHistorico) {
              setShowSelecionaHistorico(false);
            }
          }}
        />
        <ButtonSp
          className="p-button-primary"
          label="Imprimir"
          icon="pi pi-print"
          disabled={!idSistemaReport}
          onClick={() => {
            if (setShowSelecionaHistorico) {
              setShowSelecionaHistorico(false);
              printHistoricoVersao(idSistemaReport);
            }
          }}
        />
      </div>
    );
    return (
      <Dialog
        closable={false}
        footer={footer}
        header="Selecione o Sistema"
        visible={showSelecionaHistorico}
        contentStyle={{ minHeight: '100px' }}
        style={{ minWidth: '220px', maxWidth: '420px', width: '30%' }}
        modal
        onHide={() => {
          if (setShowSelecionaHistorico) {
            setShowSelecionaHistorico(false);
          }
        }}
      >
        <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
          <LabelSp>Sistema</LabelSp>
          <DropdownSp
            required
            placeholder="Selecione"
            appendTo={document.body}
            filter
            onChange={e => {
              setIdSistemaReport(e.target.value);
            }}
            value={idSistemaReport}
            options={sistemas}
          />
        </div>
      </Dialog>
    );
  }

  function selectedDevTemplate(option) {
    if (isNumber(option)) {
      const cor = corStatus(Number(option));
      const item = listaStatusDesenvolvimento[option];
      return (
        <div className="multiselected-item">
          <div
            className="div-status"
            style={{
              marginRight: 3,
              background: cor,
              color: cor,
            }}
          >
            A
          </div>
          <span>{item.label}</span>
        </div>
      );
    }

    return <span>Todos</span>;
  }
}
