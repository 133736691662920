import api from 'axios';
import { store } from '../store';
import { loading, loadingSuccess } from '../store/modules/global/actions';

class MonitorService {
  setLoading(value) {
    if (value) {
      store.dispatch(loading());
    } else {
      store.dispatch(loadingSuccess());
    }
  }

  async monitorar(uri, token) {
    this.setLoading(true);
    try {
      const response = await api.get(uri, {
        headers: { Authorization: `${token}` },
      });
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }
}
export default new MonitorService();
