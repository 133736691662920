import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';

import logo from '../../assets/images/logo300.png';
import { Container } from './styles';

import { loginRequest } from '../../store/modules/auth/actions';
import { validateFields } from '../../util/functions';
import LabelSp from '../../components/LabelSp';
import InputTextSp from '../../components/InputTextSp';

export default function Login() {
  const [login, setLogin] = useState([]);
  const [senha, setSenha] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setLogin('');
    setSenha('');
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(loginRequest(login, senha, '/home'));
  }

  // const data = 'lorem <b>ipsum</b>';

  return (
    <Container className="page-enter-tab">
      <form onSubmit={handleSubmit}>
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <img src={logo} alt="" />
          </div>

          <div className="p-col-12">
            <LabelSp>Usuário</LabelSp>
            <div className="p-inputgroup">
              <InputTextSp
                autoFocus
                type="text"
                name="login"
                placeholder="seu login"
                required
                value={login}
                onChange={e => setLogin((e.target.value || '').toLowerCase())}
              />
              <span className="p-inputgroup-addon">
                <i className="pi pi-user" />
              </span>
            </div>
          </div>
          <div className="p-col-12">
            <LabelSp>Senha</LabelSp>
            <div className="p-inputgroup">
              <InputTextSp
                type="password"
                name="password"
                placeholder="sua senha"
                required
                value={senha}
                onChange={e => setSenha(e.target.value)}
              />
              <span className="p-inputgroup-addon">
                <i className="pi pi-key" />
              </span>
            </div>
          </div>

          <div className="p-col-12">
            <Button
              type="submit"
              label="Login"
              disabled={!validateFields({ login, senha }, ['login', 'senha'])}
            />
          </div>
        </div>
        {/* <div
          dangerouslySetInnerHtml={{
            __html: data,
          }}
        /> */}
      </form>
    </Container>
  );
}
