import React from 'react';
import { Switch } from 'react-router-dom';

import Home from '../pages/Home';
import Route from './Route';
import Login from '../pages/Login';
import Usuario from '../pages/Usuario';

import AcessDenied from '../pages/AcessDenied';

import NotFound from '../pages/NotFound';
import Colaborador from '../pages/Colaborador';
import Revenda from '../pages/Revenda';
import Cliente from '../pages/Cliente';
import ChamadoTipo from '../pages/ChamadoTipo';
import Chave from '../pages/Chave';
import ClienteBackup from '../pages/ClienteBackup';
import Chamado from '../pages/Chamado';
import Desenvolvimento from '../pages/Desenvolvimento';
import Dashboard from '../pages/Dashboard';
import AtendimentoTopico from '../pages/AtendimentoTopico';
import AtendimentoClassificacao from '../pages/AtendimentoClassificacao';
import Campanha from '../pages/Campanha';
import AcompanhamentoVenda from '../pages/AcompanhamentoVenda';
import Auditoria from '../pages/Auditoria';
import UsuarioPerfil from '../pages/UsuarioPerfil';
import MonitorApi from '../pages/MonitorApi';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/login" exact component={Login} />
      <Route path="/denied" exact component={AcessDenied} />
      <Route path="/home" component={Home} isPrivate />
      <Route path="/notFound" component={NotFound} />

      {/* Monitor de APIs */}
      <Route path="/monitor" component={MonitorApi} isPrivate />

      {/* usuarios  */}
      <Route
        path="/usuarios/:option"
        component={Usuario}
        roles={['USUARIO_LER', 'USUARIO_ISERIR', 'USUARIO_ALTERAR']}
      />
      <Route path="/usuarios" component={Usuario} roles={['USUARIO_ACESSAR']} />

      {/* colaboradores  */}
      <Route
        path="/colaboradores/:option"
        component={Colaborador}
        roles={['COLABORADOR_LER', 'COLABORADOR_INSERIR', 'COLABORADOR_ALTERAR']}
      />
      <Route path="/colaboradores" component={Colaborador} roles={['COLABORADOR_ACESSAR']} />

      {/* revendas  */}
      <Route
        path="/revendas/:option"
        component={Revenda}
        roles={['REVENDA_LER', 'REVENDA_INSERIR', 'REVENDA_ALTERAR']}
      />
      <Route path="/revendas" component={Revenda} roles={['REVENDA_ACESSAR']} />

      {/* clientes  */}
      <Route
        path="/clientes/:option"
        component={Cliente}
        roles={['CLIENTE_LER', 'CLIENTE_INSERIR', 'CLIENTE_ALTERAR']}
      />
      <Route path="/clientes" component={Cliente} roles={['CLIENTE_ACESSAR']} />

      {/* chamados tipo  */}
      <Route
        path="/chamadosTipo/:option"
        component={ChamadoTipo}
        roles={['CHAMADO_TIPO_LER', 'CHAMADO_TIPO_INSERIR', 'CHAMADO_TIPO_ALTERAR']}
      />
      <Route path="/chamadosTipo" component={ChamadoTipo} roles={['CHAMADO_TIPO_ACESSAR']} />

      {/* chave */}
      <Route path="/chaves" component={Chave} roles={['CHAVE_ACESSAR']} />

      {/* dashboard */}
      <Route path="/dashboard" component={Dashboard} roles={['DASHBOARD_ACESSAR']} />

      <Route path="/clientesBackup" component={ClienteBackup} roles={['BACKUP_CLIENTE']} />

      {/* chamados  */}
      <Route
        path="/chamados/:option"
        component={Chamado}
        roles={['CHAMADO_LER', 'CHAMADO_INSERIR', 'CHAMADO_ALTERAR']}
      />
      <Route path="/chamados" component={Chamado} roles={['CHAMADO_ACESSAR']} />

      {/* desenvolvimento  */}
      <Route
        path="/desenvolvimentos/:option"
        component={Desenvolvimento}
        roles={['DESENVOLVIMENTO_LER', 'DESENVOLVIMENTO_INSERIR', 'DESENVOLVIMENTO_ALTERAR']}
      />
      <Route
        path="/desenvolvimentos"
        component={Desenvolvimento}
        roles={['DESENVOLVIMENTO_ACESSAR']}
      />

      {/* atendimentoTopico  */}
      <Route
        path="/atendimentoTopicos/:option"
        component={AtendimentoTopico}
        roles={[
          'ATEND_TOPICO_CLASSIFICADOR_ACESSAR',
          'ATEND_TOPICO_CLASSIFICADOR_INSERIR',
          'ATEND_TOPICO_CLASSIFICADOR_ALTERAR',
        ]}
      />
      <Route
        path="/atendimentoTopicos"
        component={AtendimentoTopico}
        roles={['ATEND_TOPICO_CLASSIFICADOR_ACESSAR']}
      />

      {/* atendimentoClassificacoes  */}
      <Route
        path="/atendimentoClassificacoes/:option"
        component={AtendimentoClassificacao}
        roles={[
          'ATEND_CLASSIFICACAO_ACESSAR',
          'ATEND_CLASSIFICACAOO_INSERIR',
          'ATEND_CLASSIFICACAO_ALTERAR',
        ]}
      />
      <Route
        path="/atendimentoClassificacoes"
        component={AtendimentoClassificacao}
        roles={['ATEND_CLASSIFICACAO_ACESSAR']}
      />

      {/* campanhas  */}
      <Route
        path="/campanhas/:option"
        component={Campanha}
        roles={['CAMPANHA_ACESSAR', 'CAMPANHA_INSERIR', 'CAMPANHA_ALTERAR']}
      />
      <Route path="/campanhas" component={Campanha} roles={['CAMPANHA_ACESSAR']} />

      {/* acompanhamentoVendas  */}
      <Route
        path="/acompanhamentoVendas/:option"
        component={AcompanhamentoVenda}
        roles={[
          'ACOMPANHAMENTO_VENDA_LER',
          'ACOMPANHAMENTO_VENDA_INSERIR',
          'ACOMPANHAMENTO_VENDA_ALTERAR',
        ]}
      />
      <Route
        path="/acompanhamentoVendas"
        component={AcompanhamentoVenda}
        roles={['ACOMPANHAMENTO_VENDA_ACESSAR']}
      />

      {/* auditoria  */}
      <Route
        path="/auditoria/:option"
        component={Auditoria}
        roles={['AUDITORIA_LER', 'AUDITORIA_INSERIR', 'AUDITORIA_ALTERAR']}
      />
      <Route path="/auditoria" component={Auditoria} roles={['AUDITORIA_ACESSAR']} />

      {/* perfil acesso  */}
      <Route
        path="/usuarioPerfis/:option"
        component={UsuarioPerfil}
        roles={['USUARIO_PERFIL_LER', 'USUARIO_PERFIL_INSERIR', 'USUARIO_PERFIL_ALTERAR']}
      />
      <Route
        path="/usuarioPerfis"
        component={UsuarioPerfil}
        roles={['USUARIO_PERFIL_ACESSAR']}
      />

      {/* se não encontrar nenhuma rota, vá para not found */}
      <Route path="/" isPrivate component={NotFound} />
    </Switch>
  );
}
